<template>
  <b-sidebar
    class="nav-sidebar-outer d-print-none"
    sidebar-class="nav-sidebar"
    header-class="px-2 py-3 w-100 nav-sidebar-header"
    body-class="d-flex flex-column align-items-center justify-content-center"
    :visible="sideMenuExpanded"
    left
    width="260px"
    backdrop-variant="light"
    backdrop
    no-header-close
    bg-variant="main-yellow"
    text-variant="dark"
    @hidden="closeSideBar"
  >
    <!-- default title header -->
    <template v-slot:title="{ hide }">
      <b-row
        class="d-flex flex-row align-items-center justify-content-between px-0 w-100"
        no-gutters
      >
        <!-- title logo -->
        <b-navbar-brand class="px-0 mx-0 my-1" to="/">
        <div class="logo-box">
          <b-img
            src="@/assets/Asset 8.png"
            class="img-logo"
            height="25px"
            alt="Finance"
          ></b-img>
        </div>
        </b-navbar-brand>
        <!-- close button -->
        <b-button
          variant="transparent"
          pill
          class="close px-2 py-1 mr-0"
          @click="hide()"
        >
          <b-icon icon="x" variant="dark" aria-label="Close Sidebar"></b-icon>
        </b-button>
      </b-row>
    </template>
    <!-- sidebar default content -->
    <template v-slot:default>
      <b-col
        class="d-flex flex-column justify-content-start align-items-start nav-container px-0 ml-4"
        is-nav
      >
        <b-navbar-nav
          class="d-flex flex-column align-items-start justify-content-start"
        >
          <template>
            <!-- for testing purpose  -->
            <!-- the exact is used to make sure the roue / is exactly matched -->
            <b-nav-item
              to="/"
              exact
              title="Dashboard"
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-2"
                no-gutters
              >
                <b-icon
                  icon="house-door"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
                />
                <span
                  class="side-nav-text font-primary font-weight-normal text-left"
                >
                  Dashboard
                </span>
              </b-row>
            </b-nav-item>
            <b-nav-item
              to="/invoices"
              exact
              title="Invoices"
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-2"
                no-gutters
              >
                <b-icon
                  icon="receipt"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
                />
                <span
                  class="side-nav-text font-primary font-weight-normal text-left"
                >
                  Invoices
                </span>
                <span
                  class="side-nav-text font-primary font-weight-normal text-left ml-4"
              >
              (Sales & Purchases)
              </span>
              </b-row>
            </b-nav-item>
            <b-nav-item
              to="/quotes"
              exact
              title="Quotes"
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-2"
                no-gutters
              >
                <b-icon
                  icon="file-text"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
                />
                <span
                  class="side-nav-text font-primary font-weight-normal text-left"
                >
                  Quotes
                </span>
              </b-row>
            </b-nav-item>
            <b-nav-item
              to="/customers"
              title="Customers"
              exact
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-2"
                no-gutters
              >
                <b-icon
                  icon="people"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
                />
                <span
                  class="side-nav-text font-primary font-weight-normal text-left"
                >
                  Customers
                </span>
              </b-row>
            </b-nav-item>
            <b-nav-item
              to="/supplier"
              title="Allows bank statements to be uploaded and reconciled - individual and bulk."
              exact
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
             
          >
            <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-2"
                no-gutters
            >
              <b-icon
                  icon="briefcase"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
              />
              <span
                  class="side-nav-text font-primary font-weight-normal text-left"
              >
              Suppliers
              </span>
            </b-row>
          </b-nav-item>
            <b-nav-item
              to="/products"
              title="Item Codes"
              exact
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-2"
                no-gutters
              >
                <b-icon
                  icon="box"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
                />
                <span
                  class="side-nav-text font-primary font-weight-normal text-left"
                >
                  Item Codes
                </span>
              </b-row>
            </b-nav-item>
            <b-nav-item
              to="/transactions"
              title="Transactions"
              exact
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-2"
                no-gutters
              >
                <b-icon
                  icon="cash-stack"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
                />
                <span
                  class="side-nav-text font-primary font-weight-normal text-left"
                >
                Bank Reconciliations
                </span>
              </b-row>
            </b-nav-item>
            <b-nav-item
              to="/manualJournals"
              title="Allows bank statements to be uploaded and reconciled - individual and bulk."
              exact
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
             
          >
            <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-2"
                no-gutters
            >
              <b-icon
                  icon="file-text"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
              />
              <span
                  class="side-nav-text font-primary font-weight-normal text-left"
              >
              Manual Journals
              </span>
            </b-row>
          </b-nav-item>
            <b-nav-item
              to="/charts"
              title="Chart of Accounts"
              exact
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-2"
                no-gutters
              >
                <b-icon
                  icon="file-earmark-spreadsheet"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
                />
                <span
                  class="side-nav-text font-primary font-weight-normal text-left"
                >
                  Chart of Accounts
                </span>
              </b-row>
            </b-nav-item>
            <!--            <b-nav-item-->
            <!--              title="Sales"-->
            <!--              class="side-nav-item my-2"-->
            <!--              link-classes="p-0"-->
            <!--              active-class="active-link"-->
            <!--              v-b-toggle.collapse-a.collapse-b.collapse-c-->
            <!--            >-->
            <!--              <b-row-->
            <!--                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-2"-->
            <!--                no-gutters-->
            <!--              >-->
            <!--                <b-icon-->
            <!--                  icon="newspaper"-->
            <!--                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"-->
            <!--                />-->
            <!--                <span-->
            <!--                  class="side-nav-text font-primary font-weight-normal text-left"-->
            <!--                >-->
            <!--                  Sales-->
            <!--                </span>-->
            <!--              </b-row>-->
            <!--              <b-collapse id="collapse-a" class="mt-2">-->
            <!--                <b-nav-item-->
            <!--                  to="/invoices"-->
            <!--                  title="Invoices"-->
            <!--                  class="side-nav-subitem"-->
            <!--                  @click.prevent-->
            <!--                >-->
            <!--                  <b-row-->
            <!--                    class="d-flex flex-row align-items-center justify-content-start mx-auto"-->
            <!--                    no-gutters-->
            <!--                  >-->
            <!--                    <span-->
            <!--                      class="side-nav-text font-primary font-weight-normal text-left"-->
            <!--                    >-->
            <!--                      Invoices-->
            <!--                    </span>-->
            <!--                  </b-row>-->
            <!--                </b-nav-item>-->
            <!--              </b-collapse>-->
            <!--              <b-collapse id="collapse-b" class="mt-2">-->
            <!--                <b-nav-item-->
            <!--                    to="/quotes"-->
            <!--                    title="Invoices"-->
            <!--                    class="side-nav-subitem"-->
            <!--                    @click.prevent-->
            <!--                >-->
            <!--                  <b-row-->
            <!--                      class="d-flex flex-row align-items-center justify-content-start mx-auto"-->
            <!--                      no-gutters-->
            <!--                  >-->
            <!--                    <span-->
            <!--                        class="side-nav-text font-primary font-weight-normal text-left"-->
            <!--                    >-->
            <!--                      Quotes-->
            <!--                    </span>-->
            <!--                  </b-row>-->
            <!--                </b-nav-item>-->
            <!--              </b-collapse>-->
            <!--              <b-collapse id="collapse-b">-->
            <!--                <b-nav-item-->
            <!--                  to="/customers"-->
            <!--                  title="Customers"-->
            <!--                  class="side-nav-subitem"-->
            <!--                  @click.prevent-->
            <!--                >-->
            <!--                  <b-row-->
            <!--                    class="d-flex flex-row align-items-center justify-content-start mx-auto"-->
            <!--                    no-gutters-->
            <!--                  >-->
            <!--                    <span-->
            <!--                      class="side-nav-text font-primary font-weight-normal text-left"-->
            <!--                    >-->
            <!--                      Customers-->
            <!--                    </span>-->
            <!--                  </b-row>-->
            <!--                </b-nav-item>-->
            <!--              </b-collapse>-->
            <!--              <b-collapse id="collapse-c">-->
            <!--                <b-nav-item-->
            <!--                  to="/products"-->
            <!--                  title="Products & Services"-->
            <!--                  class="side-nav-subitem"-->
            <!--                  @click.prevent-->
            <!--                >-->
            <!--                  <b-row-->
            <!--                    class="d-flex flex-row align-items-center justify-content-start mx-auto"-->
            <!--                    no-gutters-->
            <!--                  >-->
            <!--                    <span-->
            <!--                      class="side-nav-text font-primary font-weight-normal text-left"-->
            <!--                    >-->
            <!--                      Products & Services-->
            <!--                    </span>-->
            <!--                  </b-row>-->
            <!--                </b-nav-item>-->
            <!--              </b-collapse>-->
            <!--            </b-nav-item>-->
            <!--            <b-nav-item-->
            <!--              title="Accounting"-->
            <!--              class="side-nav-item my-2"-->
            <!--              link-classes="p-0"-->
            <!--              active-class="active-link"-->
            <!--              v-b-toggle.collapse-d.collapse-e-->
            <!--            >-->
            <!--              <b-row-->
            <!--                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-2"-->
            <!--                no-gutters-->
            <!--              >-->
            <!--                <b-icon-->
            <!--                  icon="cash"-->
            <!--                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"-->
            <!--                />-->
            <!--                <span-->
            <!--                  class="side-nav-text font-primary font-weight-normal text-left"-->
            <!--                >-->
            <!--                  Accounting-->
            <!--                </span>-->
            <!--              </b-row>-->
            <!--              <b-collapse id="collapse-d" class="mt-2">-->
            <!--                <b-nav-item-->
            <!--                  to="/transactions"-->
            <!--                  title="Transactions"-->
            <!--                  class="side-nav-subitem"-->
            <!--                  @click.prevent-->
            <!--                >-->
            <!--                  <b-row-->
            <!--                    class="d-flex flex-row align-items-center justify-content-start mx-auto"-->
            <!--                    no-gutters-->
            <!--                  >-->
            <!--                    <span-->
            <!--                      class="side-nav-text font-primary font-weight-normal text-left"-->
            <!--                    >-->
            <!--                      Transactions-->
            <!--                    </span>-->
            <!--                  </b-row>-->
            <!--                </b-nav-item>-->
            <!--              </b-collapse>-->
            <!--              <b-collapse id="collapse-e">-->
            <!--                <b-nav-item-->
            <!--                  to="/charts"-->
            <!--                  title="Chart of Accounts"-->
            <!--                  class="side-nav-subitem"-->
            <!--                  @click.prevent-->
            <!--                >-->
            <!--                  <b-row-->
            <!--                    class="d-flex flex-row align-items-center justify-content-start mx-auto"-->
            <!--                  >-->
            <!--                    <span-->
            <!--                      class="side-nav-text font-primary font-weight-normal text-left"-->
            <!--                    >-->
            <!--                      Chart of Accounts-->
            <!--                    </span>-->
            <!--                  </b-row>-->
            <!--                </b-nav-item>-->
            <!--              </b-collapse>-->
            <!--            </b-nav-item>-->
            <b-nav-item
              to="/reports"
              title="Reports"
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-2"
                no-gutters
              >
                <b-icon
                  icon="bar-chart-line"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
                />
                <span
                  class="side-nav-text font-primary font-weight-normal text-left"
                >
                  Reports
                </span>
              </b-row>
            </b-nav-item>
            <b-nav-item
              to="/assets"
              title=""
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
          >
            <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-2"
                no-gutters
            >
              <b-icon
                  icon="book"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
              />
              <span
                  class="side-nav-text font-primary font-weight-normal text-left"
              >
              Fixed Assets
              </span>
            </b-row>
          </b-nav-item>
          </template>
        </b-navbar-nav>
        <b-navbar-nav
          class="d-flex flex-column align-items-start justify-content-start"
        >
          <template v-for="(item, index) in menuArrayBottom">
            <!-- :active="sideMenuIndex == index + 1 + menuArrayTop.length ? true : false" -->
            <b-nav-item
              :key="key + index"
              :to="item.route"
              @click="routeFunc(item, index + 1 + menuArrayTop.length)"
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
            >
              <b-row
                class="d-flex flex-row align-items-start justify-content-start side-nav-item-row px-3 py-2"
                no-gutters
              >
                <b-icon
                  font-scale="2"
                  :icon="item.icon"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
                ></b-icon>
                <span
                  class="side-nav-text font-primary font-weight-normal text-left"
                  >{{ item.label }}</span
                >
              </b-row>
            </b-nav-item>
          </template>
        </b-navbar-nav>
      </b-col>
    </template>
  </b-sidebar>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "MobileSideBar",
  props: {
    sideMenuExpanded: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      key: Number(new Date()),
      menuArrayTop: [],
      menuArrayBottom: [],
      isMultiple: false,
    };
  },
  computed: {
    ...mapGetters({
      sideMenuIndex: "getSideMenuIndex",
    }),
  },
  created() {
    this.menuArrayTop = [
      {
        icon: "house-door",
        label: "Dashboard",
        route: "",
      },
      {
        icon: "newspaper",
        label: "Sales",
        route: "/sales",
      },
      {
        icon: "cash",
        label: "Accounting",
        route: "/accounting",
      },
      {
        icon: "bar-chart-line",
        label: "Reports",
        route: "/reports",
      },
    ];
    // this.menuArrayTop = [
    //   {
    //     icon: "house-door",
    //     label: "Dashboard",
    //     route: "",
    //   },
    //   {
    //     icon: "newspaper",
    //     label: "Sales",
    //     route: "/sales",
    //     isMultiple: true,
    //     content: [
    //       {
    //         label: "Invoices",
    //         route: "/invoices",
    //       },
    //       {
    //         label: "Customers",
    //         route: "/customers",
    //       },
    //     ],
    //   },
    //   {
    //     icon: "cash",
    //     label: "Accounting",
    //     route: "/accounting",
    //     isMultiple: true,
    //     content: [
    //       {
    //         label: "Transactions",
    //         route: "/transactions",
    //       },
    //       {
    //         label: "Chart Of Accounts",
    //         route: "/chartofaccounts",
    //       },
    //     ],
    //   },
    //   {
    //     icon: "bar-chart-line",
    //     label: "Reports",
    //     route: "/reports",
    //   },
    // ];
    this.menuArrayBottom = [
    {
        active: false,
        icon: "folder",
        label: "Workpaper Folder",
        route: "/workpaperFolder",
       
      },
      {
        active: false,
        icon: "gear",
        label: "Settings",
        route: "/settings",
      },
      {
        active: false,
        icon: "box-arrow-in-right",
        label: "Sign Out",
        route: "/login",
      },
    ];
    // if (localStorage.getItem("masterAdmin")) {
    //   let admin = JSON.parse(localStorage.getItem("masterAdmin"));
    //   this.adminType = admin.adminType;
    // }
  },
  methods: {
    closeSideBar() {
      this.$store.dispatch("general/setIsSideMenuExpanded", false);
      // this.$emit("onClose");
    },
    logout() {
      // this.$store.dispatch("setSideMenuIndex", 0);
      localStorage.clear();
      this.$store.dispatch("setIsLoading", false);
      this.$router.push("/login").catch((err) => {
        console.log("LOGOUT ERROR: ", err);
      });
      // location.reload();
    },
    routeFunc(item) {
      // console.log(item.route, this.$route.path);
      // console.log(item);
      this.key = Number(new Date());
      this.$store.dispatch("setIsLoading", false);
      // this.$store.dispatch("setSideMenuIndexKey", this.key);
      // this.$store.dispatch("setSideMenuIndex", index);
      this.$store.dispatch("setIsSideMenuExpanded", false);

      if (item.label === "Logout") {
        this.logout();
      } else {
        this.$router.push(item.route).catch((err) => {
          console.log("ROUTE SWITCH ERROR: ", err);
        });
      }
    },
  },
};
</script>
<style scoped>
.nav-sidebar-outer .nav-container {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.nav-sidebar-outer .logo-img {
  max-height: 60px;
}
.nav-sidebar-outer >>> .nav-sidebar-header {
  font-size: 1rem;
}
.nav-sidebar-outer >>> .nav-sidebar-header strong {
  width: 100%;
  font-weight: normal;
  font-size: 1rem;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.nav-sidebar-outer .nav-container::-webkit-scrollbar {
  width: 0.5rem;
  scrollbar-width: thin;
  background-color: var(--dark);
}
.nav-sidebar-outer .nav-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--primary);
}
.nav-sidebar-outer .nav-container::-webkit-scrollbar-thumb {
  background-color: var(--light);
}
/* side nav design */
.navbar .navbar-nav {
  width: 90%;
}
/* side nav design */
.navbar .navbar-nav .side-nav-item {
  width: 100%;
}
/* side nav logo box */
.logo-box {
  /* box-sizing: border-box; */
  position: relative;
  width: 150px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background: rgba(255, 255, 255, 0.25); */
  /* background: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12.5px);
  border-radius: 5px; */
}
/* nav link (icon/text) color (default state)  */
.side-nav-item a.nav-link .side-nav-item-row {
  width: 100%;
  border-radius: 0.25rem;
  background-color: transparent;
  padding: 0.8rem 1.25rem;
  transition: background-color 100ms linear 0s, color 100ms linear 0s;
}
.side-nav-item a.nav-link .side-nav-item-row .side-nav-icon {
  color: black;
  transition: color 100ms ease-in;
  user-select: none;
  height: 26px;
  width: 26px;
}
.side-nav-item a.nav-link .side-nav-item-row .side-nav-text {
  font-size: 1rem;
  color: black;
  padding-left: 0.65rem;
  transition: color 100ms ease-in, opacity 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
  opacity: 1;
  user-select: none;
}
/* nav link (icon/text) hover color (default state) */
.side-nav-item a.nav-link:hover .side-nav-item-row {
  background-color: var(--light);
}
.side-nav-item a.nav-link:hover .side-nav-item-row .side-nav-icon,
.side-nav-item a.nav-link:hover .side-nav-item-row .side-nav-text {
  color: black;
}
/* nav link (icon/text) color (active state)  */
.side-nav-item a.nav-link.active-link .side-nav-item-row {
  cursor: auto;
  background-color: var(--light);
}
.side-nav-item a.nav-link.active-link .side-nav-item-row .side-nav-icon {
  color: black;
}
.side-nav-item a.nav-link.active-link .side-nav-item-row .side-nav-text {
  color: black;
}
/* sub item styling */
.side-nav-item .side-nav-subitem {
  width: 100%;
  max-width: 80%;
  margin-left: 20%;
  color: var(--white);
}
/* nav sub item default state */
.side-nav-item .side-nav-subitem .side-nav-text {
  color: black;
}

/* nav sub item hover state */
.side-nav-item .side-nav-subitem .side-nav-text:hover {
  color: black;
}
.side-nav-item a.nav-link.active-link .side-nav-subitem .side-nav-text {
  color: black;
}
</style>
