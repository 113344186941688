import axios from "axios";

export const GetUserProfile = async () => {
  try {
    return await axios.get("/auth/me");
  } catch (error) {
    return error;
  }
};

export const UpdateUserProfile = async (payload) => {
  try {
    return await axios.put(`/auth/update`, payload);
  } catch (error) {
    return error;
  }
};

export const ChangePassword = (payload) => {
  return axios.put(`/auth/changePassword`, payload);
};

export const setDefaultBussiness = async (payload) => {
  try {
    return await axios.put(`/auth/default`, payload);
  } catch (error) {
    return error;
  }
};

export const postFeedback = async (payload) => {
  return await axios.post(`/feedback`, payload);
};
